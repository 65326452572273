import styles from './Features.module.css';

const Features = () => {
    return (
        <div className={styles.main}>
            <div className="simple-container">
                <h1>Express in Emojis</h1>
                <p>Nobody likes to write a journal.<br />That&apos;s why we created Dwoth.</p>
                <div className={styles.features}>
                    <div className={styles.feature}>
                        <i className="fal fa-bolt"></i>
                        <h3>Super quick</h3>
                        <p>Tap on an emoji that suits your activity, write a line of info if you want. That&apos;s it.</p>
                    </div>
                    <div className={styles.feature}>
                        <i className="fal fa-globe"></i>
                        <h3>Access anywhere</h3>
                        <p>All data is synced in real-time. So you can access your activities on any device.</p>
                    </div>
                    <div className={styles.feature}>
                        <i className="fal fa-lock"></i>
                        <h3>Fully secure</h3>
                        <p>All data is stored securely. This ensures your privacy is not compromised.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;