import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Payment.module.css';

const PleaseWait = () => {
    const navigate = useNavigate();

    const getPaymentStatus = async () => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/details`, {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })

        console.log(res.data);

        if (res.data.status === 'ok') {
            if (res.data.renewalDate > new Date()) {
                return navigate('/payment/success');
            }
            return navigate('/payment/failed');
        } else {
            return navigate('/payment/failed');
        }
    }

    useEffect(() => {
        getPaymentStatus();
    }, []);

    return (
        <div className={styles.main}>
            <i className={"fad fa-spinner-third " + styles.loading}></i>
            <h1>Please wait...</h1>
            <p>Your payment is being processed.</p>
        </div>
    )
}

export default PleaseWait;