import { useState, useEffect } from 'react';

import timezoneList from '../../timezones-list';

import styles from './Settings.module.css';

const Settings = (props) => {
    const [timezone, setTimezone] = useState(props.timezone);

    const changeTimezoneHandler = () => {
        props.changeTimezoneHandler(timezone);
        props.onCloseHandler();
    }

    const submitHandler = () => {
        changeTimezoneHandler();
    }

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Settings</h2>
                <div className={styles.item}>
                    <span className={styles.title}>Prefered Timezone:</span>
                    <select onChange={(e) => setTimezone(timezoneList[e.target.selectedIndex - 1].name)} className={styles.select} >
                        <option key={timezone + "_main"} value={timezone}>{timezone}</option>
                        {timezoneList.map(timezone => (
                            <option key={timezone.name} value={timezone.name}>{timezone.name}</option>
                        ))}
                    </select>
                    <span className={styles.description}>Current selected timezone: {timezone}</span>
                </div>
                <div className={styles.buttons}>
                    <button onClick={props.onCloseHandler} type="button" className={styles.cancel}>Cancel</button>
                    <button onClick={submitHandler} className={styles.create}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default Settings;