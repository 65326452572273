import { useState } from 'react';

import styles from './ConfirmDelete.module.css';

const ConfirmDelete = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const deleteHandler = () => {
        props.onConfirmHandler();
        setIsLoading(true);
    }

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Confirm deletion</h2>
                <p>Are you sure you want to delete this item? This action cannot be undone.</p>
                <div className={styles.buttons}>
                    <button onClick={props.onCloseHandler} className={styles.cancel}>Cancel</button>
                    <button onClick={deleteHandler} className={`button ${styles.delete}`}>
                        {!isLoading ? 'Delete' : (
                            <i className="far fa-spinner-third"></i>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDelete;