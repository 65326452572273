import { useState, useEffect } from 'react';

import Card from './ActivityCard';

import styles from './MonthGroup.module.css';

const MonthGroup = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const getNumberOfDays = (date) => {
        const diffInTime = new Date().getTime() - new Date(date).getTime();
        return Math.abs(Math.round(diffInTime / 86400000));
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    useEffect(() => {
        const date = `${new Date().getMonth()}-${new Date().getFullYear()}`;
        if (date === props.date) {
            setIsOpen(true);
        }
    }, [])

    return (
        <div className={styles.month}>
            <div className={styles.toggle} onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? (
                    <i className="fal fa-angle-right" />
                ) : (
                    <i className="fal fa-angle-down" />
                )}
                <span>
                    {months[props.date.split('-')[0]] + " " + props.date.split('-')[1]}
                </span>
            </div>
            {isOpen && (
                <div className={styles.cards}>
                    {props.activities.map(activity => {
                        const date = new Date(new Date(activity.date).toLocaleString("en-US", { timeZone: props.timezone }));
                        let hidden = false;
                        if (getNumberOfDays(date) > 365 && !props.isPremiumUser) {
                            hidden = true;
                        }
                        return (
                            <Card key={activity.id} id={activity.id} text={activity.text} emoji={props.emojies[activity.emoji]} date={date} onActivityDelete={props.onActivityDelete} onActivityEdit={props.onActivityEdit} throwAlert={props.throwAlert} openUpgradeHero={props.openUpgradeHero} hidden={hidden} />
                        )
                    })}
                </div>
            )}
        </div>
    );
}

export default MonthGroup;