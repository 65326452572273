import { useState, useEffect } from 'react';

import styles from './ChangeText.module.css';

const ChangeText = (props) => {
    const [error, setError] = useState(false);
    const [text, setText] = useState(props.text);

    const submitHandler = (e) => {
        e.preventDefault();

        if (text.length < 1) {
            setError(true);
            return;
        }

        props.onTextChange(text);
        props.onCloseHandler();
    }

    useEffect(() => {
        if (text.length >= 1) {
            setError(false);
        }
    }, [text]);

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Edit Journal Text</h2>
                <form onSubmit={submitHandler}>
                    <input className={error ? 'error-input' : ''} type="text" placeholder="Activity Name" autoFocus={true} onChange={(e) => setText(e.target.value)} value={text} />
                    {error && <p className="error-feedback"><i className="fal fa-exclamation-triangle"></i> Please enter a valid text.</p>}
                    <div className={styles.buttons}>
                        <button type="button" onClick={props.onCloseHandler} className={styles.cancel}>Cancel</button>
                        <button type="submit" className={styles.create}>Update</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangeText;