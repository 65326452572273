import MonthGroup from './MonthGroup';
import CountCard from './CountCard';
import SearchBar from './SearchBar';
import NoActivities from './NoActivities';

import styles from './Cards.module.css';

const Cards = (props) => {
    const processedActivities = {};

    props.activities.forEach(activity => {
        const date = `${new Date(activity.date).getMonth()}-${new Date(activity.date).getFullYear()}`;
        processedActivities[date] = [...(processedActivities[date] || []), activity];
    });

    return (
        <>
            <SearchBar searchActivitiesHandler={props.searchActivitiesHandler} />
            <div className={styles.wrapper}>
            {Object.keys(processedActivities).map(date => {
                return (
                    <MonthGroup key={date} date={date} activities={processedActivities[date]} isPremiumUser={props.isPremiumUser} timezone={props.timezone} todayJournalsCount={props.todayJournalsCount} onActivityDelete={props.onActivityDelete} onActivityEdit={props.onActivityEdit} emojies={props.emojies} searchActivitiesHandler={props.searchActivitiesHandler} openUpgradeHero={props.openUpgradeHero} />
                )
            })}
            </div>
	    {props.activities.length === 0 && <NoActivities />}
            <CountCard isPremiumUser={props.isPremiumUser} todayJournalsCount={props.todayJournalsCount} />
        </>
    )
}

export default Cards;