import styles from './FullLoading.module.css';

const FullLoading = () => {
    return (
        <div className={styles.loading}>
            <i className="fad fa-spinner-third"></i>
        </div>
    )
}

export default FullLoading;