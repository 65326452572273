import { useState } from 'react';
import axios from 'axios';

import styles from './Default.module.css';

const LimitReached = (props) => {
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        setLoading(true);

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/new-subscription`, {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })

        if (res.data.status === 'ok' && res.data.url) {
            window.location.href = res.data.url;
        } else if (res.data.status === 'error' && res.data.message === "User not found") {
            props.throwAlert("User not found. Please login again.");
            localStorage.removeItem('token');
            window.location.href = '/login';
        } else if (res.data.status === 'error' && res.data.message === "User already subscribed") {
            props.throwAlert("You are already subscribed.");
            props.onCloseHandler();
        } else {
            props.throwAlert("Something went wrong. Please try again.");
            props.onCloseHandler();
        }

        setLoading(false);
    }

    const defaultButtonContent = (
        <p>Upgrade<span style={{
            fontSize: '0.8rem',
            marginLeft: '0.3rem',
            color: '#fcfcfc'
        }}> — $4.99/mo</span></p>
    )

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Upgrade to Premium 👑</h2>
                <p>Upgrade to Dwoth Premium at just $4.99/month and enjoy 💎</p>
                <ul>
                    <li>Log unlimited activities</li>
                    <li>Early access to features</li>
                    <li>Priority support</li>
                    <li>Export to CSV (coming soon)</li>
                    <li>&amp; so much more.</li>
                </ul>
                <div className={styles.buttons}>
                    <button onClick={props.onCloseHandler} type="button" className={styles.cancel}>Not yet</button>
                    <button onClick={submitHandler} className={styles.loading} disabled={loading}>{loading ? <i className="fad fa-spinner-third"></i> : defaultButtonContent}</button>
                </div>
            </div>
        </div>
    )
}

export default LimitReached;