import axios from 'axios';

import styles from './Billing.module.css';

const Billing = (props) => {
    const subscriptionStartDate = `${props.subscriptionStartDate.getDate()}/${props.subscriptionStartDate.getMonth() + 1}/${props.subscriptionStartDate.getFullYear()}`;
    const subscriptionEndDate = `${props.subscriptionEndDate.getDate()}/${props.subscriptionEndDate.getMonth() + 1}/${props.subscriptionEndDate.getFullYear()}`;

    const openDashboardHandler = async () => {
        props.setLoadingTrue();

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/new-dashboard-session`, {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })

        if (res.data.status === 'ok' && res.data.url) {
            window.location.href = res.data.url;
        } else if (res.data.status === 'error' && res.data.message === "User not found") {
            props.throwAlert("User not found. Please login again.");
            localStorage.removeItem('token');
            window.location.href = '/login';
        } else {
            props.throwAlert("Something went wrong. Please try again.");
        }
    }

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h2>Billing</h2>
                    <button onClick={props.onCloseHandler}>
                        <i className="fal fa-times"></i>
                    </button>
                </div>
                <p className={styles.dateP}>Subscription start: <span>{subscriptionStartDate}</span></p>
                <p className={styles.dateP}>Next billing date: <span>{subscriptionEndDate}</span></p>
                <div className={styles.options}>
                    <div className={styles.option} onClick={openDashboardHandler}>
                        <i className="fal fa-user-alt"></i>
                        <div>
                            <p className={styles.title}>Billing details</p>
                            <p className={styles.desc}>Manage your billing address and more.</p>
                        </div>
                    </div>
                    <div className={styles.option + ' ' + styles.optionSecondary} onClick={openDashboardHandler}>
                        <i className="fal fa-credit-card"></i>
                        <div>
                            <p className={styles.title}>Payment methods</p>
                            <p className={styles.desc}>Manage your saved payment methods.</p>
                        </div>
                    </div>
                    <div className={styles.option} onClick={openDashboardHandler}>
                        <i className="fal fa-history"></i>
                        <div>
                            <p className={styles.title}>Payment history</p>
                            <p className={styles.desc}>View your Dwoth subscription history.</p>
                        </div>
                    </div>
                    <div className={styles.option} onClick={openDashboardHandler}>
                        <i className="fal fa-times"></i>
                        <div>
                            <p className={styles.title}>Cancel subscription</p>
                            <p className={styles.desc}>Terminate your Dwoth subscription.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Billing;