import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainApp from './components/Pages/App';
import Home from './components/Pages/Home';

import Admin from './components/Pages/Admin';

import Login from './components/Pages/Login';
import Signup from './components/Pages/Signup';
import Logout from './components/Pages/Logout';

import PleaseWait from './components/Pages/Payment/PleaseWait';
import Success from './components/Pages/Payment/Success';
import Failed from './components/Pages/Payment/Failed';

import Terms from './components/Pages/Terms';
import Privacy from './components/Pages/Privacy';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/app" element={<MainApp />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/payment/processing" element={<PleaseWait />} />
                <Route path="/payment/success" element={<Success />} />
                <Route path="/payment/failed" element={<Failed />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;