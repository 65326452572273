import styles from './Default.module.css';

const LimitReached = (props) => {
    const upgradeButtonClickHandler = () => {
        props.onCloseHandler();
        props.openUpgradeHero();
    }

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Uh-oh. Daily Limit Reached</h2>
                <p>You can only add upto 50 journals per day with the free plan. 😔</p>
                <p>Upgrade to Dwoth premium to get: 💎</p>
                <ul>
                    <li>Log unlimited activities</li>
                    <li>Early access to features</li>
                    <li>Priority support</li>
                    <li>Export to CSV (coming soon)</li>
                    <li>&amp; so much more.</li>
                </ul>
                <div className={styles.buttons}>
                    <button onClick={props.onCloseHandler} className={styles.cancel}>Not yet</button>
                    <button onClick={upgradeButtonClickHandler} className={styles.action}>Upgrade</button>
                </div>
            </div>
        </div>
    )
}

export default LimitReached;