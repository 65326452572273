import styles from './Payment.module.css';

const Failed = () => {
    document.title = "Payment successful - Dwoth";

    setTimeout(() => {
        window.location.href = "/app";
    }, 1500);

    return (
        <div className={styles.main}>
            <i className={"far fa-times " + styles.check}></i>
            <h1>Something went wrong.</h1>
            <p>Your payment was unsuccessful. Redirecting...</p>
        </div>
    )
}

export default Failed;