import { Link } from 'react-router-dom';

import styles from './Hero.module.css';

const Hero = () => {
    document.body.classList.add('homepage');

    return (
        <div className={styles.hero}>
            <div className="simple-container">
                <h1>Journal for<br />the busy.</h1>
                <p className={styles.tagline}>A simple emoji-based journal, as you go.</p>
                <p className={styles.emojis}>🍪 📕 💪 ⚽ 🌸<br />🎬 🚗 💼 💸 💧</p>
                <Link to="/signup">
                    <button className="home-btn">
                        Sign up
                    </button>
                </Link>
                <p className={styles.plogin}>Already joined?
                    <Link to='/login'>
                        Login here.
                    </Link>
                </p>
                <div className={styles.phBadge}>
                    <a href="https://www.producthunt.com/posts/dwoth?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-dwoth" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=332396&theme=light" alt="Dwoth - The&#0032;emoji&#0032;journal | Product Hunt" style={{ width: '250px', height: '54px' }} width="250" height="54" /></a>
                </div>
            </div>
        </div>
    )
}

export default Hero;