import { useState, useEffect } from 'react';

import Header from '../Home/Header';
import Hero from '../Home/Hero';
import Features from '../Home/Features';
import Pricing from '../Home/Pricing';
import CallToAction from '../Home/CallToAction';
import Footer from '../Home/Footer';

import FullLoading from '../Modals/FullLoading';

import styles from './Home.module.css';

const Home = () => {
    document.title = "Dwoth - The Emoji Journal";

    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
                window.location.href = '/app';
                return;
        }
        setPageLoading(false);
    })

    return (
        <>
            <section className={styles.home}>
                <Header />
                <Hero />
                <Features />
                <Pricing />
                <CallToAction />
                <Footer />
            </section>
            {pageLoading && <FullLoading />}
        </>
    )
}

export default Home;