import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBpS0_mO4KSn9esUPFgpdwGWnBzObNcJvU",
    authDomain: "dwoth-app.firebaseapp.com",
    projectId: "dwoth-app",
    storageBucket: "dwoth-app.appspot.com",
    messagingSenderId: "443774426724",
    appId: "1:443774426724:web:d81718c8f8bdd9139c8971"
};

export default initializeApp(firebaseConfig);