import { useState } from 'react';

import ChangeText from './Modals/ChangeText';
import ConfirmDelete from './Modals/ConfirmDelete';

import styles from './ActivityCard.module.css';

const ActivityCard = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [deleteConf, setDeleteConf] = useState(false);

    let hour = props.date.getHours() > 12 ? props.date.getHours() - 12 : props.date.getHours();
    if (hour < 10) {
        hour = '0' + hour;
    }

    let minute = props.date.getMinutes();
    if (minute < 10) {
        minute = '0' + minute;
    }

    const date = `${props.date.getDate()}/${props.date.getMonth() + 1}/${props.date.getFullYear()} at ${hour}:${minute} ${props.date.getHours() >= 12 ? 'PM' : 'AM'}`;

    return (
        <>
            <div className={styles.card}>
                <span className={styles.emoji}>
                    {props.emoji}
                </span>
                <div className={styles.details}>
                    <div className={styles.subDetailsDiv}>
                        <span className={styles.date}>
                            {date}
                        </span>
                        <button onClick={() => setDeleteConf(true)} className={styles.delete}>
                            ❌
                        </button>
                    </div>
                    {props.text ? (
                        <span className={styles.text} onClick={() => setEditMode(true)}>
                            {props.text}
                        </span>
                    ) : (
                        <span className={styles.noText} onClick={() => setEditMode(true)}>
                            Click here to add text.
                        </span>
                    )}
                </div>
                {props.hidden && (
                    <div className={styles.hidden}>
                        <button onClick={props.openUpgradeHero} className={styles.view}>
                            Upgrade to view
                        </button>
                    </div>
                )}
            </div>
            {editMode && <ChangeText onCloseHandler={() => setEditMode(false)} text={props.text} onTextChange={(text) => props.onActivityEdit(props.id, text)} />}
            {deleteConf && <ConfirmDelete onCloseHandler={() => setDeleteConf(false)} onConfirmHandler={() => props.onActivityDelete(props.id)} />}
        </>
    )
}

export default ActivityCard;