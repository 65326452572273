import styles from './EmojiDrawer.module.css';

const EmojiDrawer = (props) => {
    const emojies = Object.keys(props.emojies);

    return (
        <>
            <div className={styles.main}>
                <div className={styles.row}>
                    {emojies.map((emoji, index) => {
                        if (index < 6) {
                            return (
                                <button onClick={() => props.onNewActivity(emoji)} key={index} className={styles.button}>
                                    {props.emojies[emoji]}
                                </button>
                            )
                        }
                    })}
                </div>
                <div className={styles.row}>
                    {emojies.map((emoji, index) => {
                        if (index >= 6) {
                            return (
                                <button onClick={() => props.onNewActivity(emoji)} key={index} className={styles.button}>
                                    {props.emojies[emoji]}
                                </button>
                            )
                        }
                    })}
                </div>
            </div>
        </>
    )
}

export default EmojiDrawer;