import UserCard from './UserCard';

import styles from './RecentUsers.module.css';

const RecentUsers = (props) => {
    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <h2>Recent Users</h2>
                <p onClick={props.viewAllUsers}>View all users</p>
            </div>
            {props.recentUsers.map(user => {
                return <UserCard key={user.uid} user={user} viewUserHandler={props.viewUserHandler} />
            })}
        </div>
    )
}

export default RecentUsers;