import styles from './NoActivities.module.css';

const NoActivities = () => {
    return (
        <div className={styles.card}>
            <p>No activities found. Tap an emoji to add one!</p>
        </div>
    )
}

export default NoActivities;