import { useState, useEffect } from 'react';
import axios from 'axios';

import timezoneList from '../../timezones-list';

import EmojiDrawer from '../EmojiDrawer';
import Header from '../Header';
import Cards from '../Cards';
import Alert from '../Alert';

import FullLoading from '../Modals/FullLoading';
import ManageAccount from '../Modals/ManageAccount';
import Settings from '../Modals/Settings';
import Billing from '../Modals/Billing';
import VerifyEmail from './VerifyEmail';

import UpgradeHero from '../Premium/UpgradeHero';
import LimitReached from '../Premium/LimitReached';

const App = () => {
    document.title = "Dwoth Journal";

    const [alert, setAlert] = useState([false, '']);
    const [loading, setLoading] = useState(true);
    const [timezone, setTimezone] = useState('');
    const [activities, setActivities] = useState([]);
    const [displayedActivities, setDisplayedActivities] = useState([]);
    const [todayJournalsCount, setTodayJournalsCount] = useState(0);
    const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
    const [isPremiumUser, setIsPremiumUser] = useState(false);
    const [noActivities, setNoActivities] = useState(true);
    const [accountOpen, setAccountOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [billingOpen, setBillingOpen] = useState(false);
    const [upgradePromptOpen, setUpgradePromptOpen] = useState(false);
    const [promptOpen, setPromptOpen] = useState(null);
    const [userName, setUserName] = useState('');
    const [isVerified, setIsVerified] = useState(true);

    async function initializeActivities() {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/all-journals`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (res.data.status === 'ok') {
            setUserName(res.data.name)
            setIsVerified(res?.data?.userStatus?.toUpperCase() === 'ACTIVE');
            setActivities(res.data.journals);
            setDisplayedActivities(res.data.journals);
            setTodayJournalsCount(res.data.todayJournalCount);
            setSubscriptionStartDate(res.data.subscriptionStart);
            setSubscriptionEndDate(res.data.renewalDate);
            if (res.data.renewalDate > new Date()) {
                setIsPremiumUser(true);
            }
            setLoading(false);
            window.scrollTo(0, document.querySelector("#root").scrollHeight);
        } else {
            if (res.data.message === "Unauthorized #2") {
                localStorage.removeItem('token');
                return window.location.href = '/';
            } else {
                throwAlert("An error occured. Please refresh.")
                console.log(res.data);
            }
        }
    }

    useEffect(() => {
        initializeActivities();
    }, []);

    useEffect(() => {
        setDisplayedActivities(activities);
    }, [activities]);

    useEffect(() => {
        if (activities.length > 0) {
            setNoActivities(false);
        } else {
            setNoActivities(true);
        }
    }, [activities]);

    useEffect(() => {
        let tzValid = false;
        if (localStorage.getItem('timezone')) {
            timezoneList.forEach(timezone => {
                if (timezone.name === localStorage.getItem('timezone')) {
                    setTimezone(timezone.name);
                    tzValid = true;
                    return;
                }
            });
            if (!tzValid) {
                setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
                localStorage.setItem('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
            }
        } else {
            setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
            localStorage.setItem('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        }
    }, []);

    useEffect(async () => {
        if (!localStorage.getItem('token')) return window.location.href = '/login';
    }, [])

    const throwAlert = (message) => {
        setAlert([true, message]);
        setTimeout(() => {
            setAlert([false, '']);
        }, 2800);
    }

    const changeTimezone = (timezone) => {
        localStorage.setItem('timezone', timezone);
        setTimezone(timezone);
    }

    const EMOJIES = {
        cookie: '🍪',
        book: '📕',
        bicep: '💪',
        football: '⚽',
        flower: '🌸',
        movie: '🎬',
        car: '🚗',
        briefcase: '💼',
        money: '💸',
        water: '💧',
    }

    const newActivityHandler = async (emoji) => {
        if (!isPremiumUser) {
            if (todayJournalsCount >= 50) {
                setPromptOpen('LimitReached');
                return;
            }
        }

        const journal = {
            id: Math.floor(Math.random() * (89998) + 10000),
            text: '',
            emoji,
            date: `${new Date()}`
        }

        setTodayJournalsCount(todayJournalsCount + 1);
        setActivities(activities => [...activities, journal]);

        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/add-new-journal`, {
            journal
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        if (res.data.status !== 'ok') {
            throwAlert("An error occured.")
            console.log(res.data)
        }
    }

    const deleteActivityHandler = async (id) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/delete-journal`, {
            journalId: id
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        if (res.data.status === 'ok') {
            setActivities(activities => activities.filter(activity => activity.id !== id));
            const activity = activities.find(activity => activity.id === id);
            if (todayJournalsCount > 0) {
                if (new Date(activity.date).toDateString() === new Date().toDateString()) {
                    setTodayJournalsCount(todayJournalsCount - 1);
                }
            }
            throwAlert("Activity deleted successfully!");
        } else {
            throwAlert("An error occured.")
            console.log(res.data)
        }
    }

    const editActivityHandler = async (id, text) => {
        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/edit-journal-title`, {
            journalId: id,
            text
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        if (res.data.status === 'ok') {
            setActivities(activities => {
                return activities.map(activity => {
                    if (activity.id === id) {
                        return {
                            ...activity,
                            text
                        }
                    }
                    return activity
                })
            })
            throwAlert("Activity edited successfully!");
        } else {
            throwAlert("An error occured.")
            console.log(res.data)
        }
    }

    const searchActivitiesHandler = (query) => {
        if (query) {
            const foundActivities = activities.filter(activity => {
                if (activity.text.toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
                if (EMOJIES[activity.emoji] === query) {
                    return true;
                }
                return false;
            });
            setDisplayedActivities(foundActivities);
            return;
        }
        setDisplayedActivities(activities);
    }

    const scrollToTopHandler = () => {
        window.scroll({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <>
            <Header isPremiumUser={isPremiumUser} accountOpen={() => setAccountOpen(true)} settingsOpen={() => setSettingsOpen(true)} billingOpen={() => setBillingOpen(true)} upgradeHeroOpen={() => setUpgradePromptOpen(true)} />
            <main className="main-app">
                <Cards isPremiumUser={isPremiumUser} timezone={timezone} todayJournalsCount={todayJournalsCount} activities={displayedActivities} onActivityDelete={deleteActivityHandler} onActivityEdit={editActivityHandler} emojies={EMOJIES} searchActivitiesHandler={searchActivitiesHandler} openUpgradeHero={() => setUpgradePromptOpen(true)} />
                <EmojiDrawer styles={{ marginTop: 'auto' }} emojies={EMOJIES} onNewActivity={newActivityHandler} />
                <div className="scroll-to-top" onClick={scrollToTopHandler}>
                    Scroll to top
                </div>
            </main>
            {!isVerified && <VerifyEmail name={userName} throwAlert={throwAlert} />}
            {alert[0] && <Alert message={alert[1]} />}
            {loading && <FullLoading />}
            {upgradePromptOpen && <UpgradeHero throwAlert={throwAlert} onCloseHandler={() => setUpgradePromptOpen(false)} />}
            {accountOpen && <ManageAccount throwAlert={throwAlert} onCloseHandler={() => setAccountOpen(false)} />}
            {settingsOpen && <Settings throwAlert={throwAlert} onCloseHandler={() => setSettingsOpen(false)} timezone={timezone} changeTimezoneHandler={changeTimezone} />}
            {billingOpen && <Billing throwAlert={throwAlert} onCloseHandler={() => setBillingOpen(false)} setLoadingTrue={() => setLoading(true)} subscriptionStartDate={new Date(subscriptionStartDate)} subscriptionEndDate={new Date(subscriptionEndDate)} />}
            {promptOpen === "LimitReached" && <LimitReached throwAlert={throwAlert} onCloseHandler={() => setPromptOpen('none')} openUpgradeHero={() => setUpgradePromptOpen(true)} />}
        </>
    )
}

export default App;