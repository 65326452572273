import { useState, useEffect } from 'react';

import app from '../../firebase-sdk';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import styles from './ManageAccount.module.css';

const ManageAccount = (props) => {
    const [error, setError] = useState(false);
    const [email, setEmail] = useState('');

    const auth = getAuth();

    const submitHandler = (e) => {
        e.preventDefault();

        if (email.trim() === '' || email.length < 3 || !email.includes('@') || !email.includes('.')) {
            setError(true);
            return;
        }

        sendPasswordResetEmail(auth, email)
            .then(() => {
                props.throwAlert('Email sent successfully. Please check your inbox.');
                props.onCloseHandler();
            })
            .catch((error) => {
                console.error(error.message);
                props.throwAlert('An error occured. Try again.')
            });

        props.onCloseHandler();
    }

    useEffect(() => {
        if (email.length >= 3 && email.includes('@') && email.includes('.')) {
            setError(false);
        }
    }, [email])

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Change Password</h2>
                <form onSubmit={submitHandler}>
                    <input className={error ? 'error-input' : ''} type="email" placeholder="Enter email" autoFocus={true} onChange={(e) => setEmail(e.target.value)} value={email} />
                    {error && <p className="error-feedback"><i className="fal fa-exclamation-triangle"></i> Please enter a valid email.</p>}
                    {!error && <p className={"error-feedback " + styles.pp}>Enter your email to change your password.</p>}
                    <div className={styles.buttons}>
                        <button onClick={props.onCloseHandler} type="button" className={styles.cancel}>Cancel</button>
                        <button type="submit" className={styles.create}>Change</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ManageAccount;