import styles from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <p>&copy; {new Date().getFullYear()} Dwoth</p>
                <a href="mailto:trydwoth@gmail.com">
                    Contact Us
                </a>
            </div>
        </footer>
    )
}

export default Footer;