import { Link } from 'react-router-dom';

import styles from './Header.module.css';

const Header = () => {
    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <img
                    src="/logo-color.png"
                    alt="Dwoth Logo"
                    className={styles.logo}
                />
                <Link to="/signup">
                    <button className={styles.button + " home-btn"}>
                        Sign up
                    </button>
                </Link>
            </div>
        </header>
    )
}

export default Header;