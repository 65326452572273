import { useState } from 'react';

import styles from './CountCard.module.css';

const CountCard = (props) => {
    const [time, setTime] = useState(null);

    setInterval(() => {
        const nowTime = new Date(new Date().toLocaleString("en-US", { timeZone: 'Asia/Kolkata' }));
        if (nowTime.getHours() === 23) {
            setTime(`${60 - nowTime.getMinutes()} minutes`);
        } else {
            setTime(`${24 - nowTime.getHours()} hours`);
        }
    }, 1000);

    return (
        <div className={styles.card} >
            {!props.isPremiumUser && <p>{props.todayJournalsCount}/50 activities logged.</p>}
            {!props.isPremiumUser && <p>Resets in {time}.</p>}

            {props.isPremiumUser && <p>{props.todayJournalsCount} activities logged today.</p>}
            {props.isPremiumUser && <p>You're a Premium user.</p>}
        </div>
    )
}

export default CountCard;