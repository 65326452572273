import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.module.css';

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModalHandler = (modal) => {
        if (modal === 'account') {
            props.accountOpen();
        } else if (modal === 'settings') {
            props.settingsOpen();
        } else if (modal === 'billing') {
            if (props.isPremiumUser) {
                props.billingOpen();
            } else {
                props.upgradeHeroOpen();
            }
        }
        setIsOpen(false);
    }

    return (
        <>
            <header className={styles.header}>
                <div className={styles.container}>
                    <img
                        src="/logo.png"
                        alt="Dwoth Logo"
                        className={styles.logo}
                    />
                    <div className={styles.accIcon}>
                        <i className={styles.profile + ' far fa-user-circle'} onClick={() => setIsOpen(!isOpen)}></i>
                        {isOpen && (
                            <div className={styles.modal}>
                                <button className={styles.pwmodal} onClick={() => openModalHandler('settings')}>
                                    Settings
                                </button>
                                <button className={styles.pwmodal} onClick={() => openModalHandler('billing')}>
                                    {props.isPremiumUser ? 'Billing' : 'Premium'}
                                </button>
                                <button className={styles.pwmodal} onClick={() => openModalHandler('account')}>
                                    Change Password
                                </button>
                                <Link to="/logout">
                                    <button>
                                        Logout
                                    </button>
                                </Link>
                            </div>
                        )}
                        {isOpen && (
                            <div className={styles.overlay} onClick={() => setIsOpen(false)}>
                            </div>
                        )}
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;