import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import app from '../../firebase-sdk';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import Alert from '../Alert';
import FullLoading from '../Modals/FullLoading';

import styles from './Signup.module.css';

const Login = () => {
    document.title = "Login - Dwoth";

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([false, false]);
    const [alert, setAlert] = useState([false, '']);
    const [pageLoading, setPageLoading] = useState(true);

    const auth = getAuth();

    const throwAlert = (message) => {
        setAlert([true, message]);
        setTimeout(() => {
            setAlert([false, '']);
        }, 2800);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            window.location.href = '/app';
        }
        setPageLoading(false);
    })

    const submitHandler = async (e) => {
        e.preventDefault();

        if (email === '' || email.length < 5 || !email.includes('@') || !email.includes('.')) {
            setError([true, error[1]]);
            return;
        }

        if (password === '' || password.length < 8) {
            setError([error[0], true]);
            return;
        }

        setLoading(true);

        signInWithEmailAndPassword(auth, email, password)
            .then((res) => {
                setLoading(false);
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/generate-auth-token`, {
                    uid: res.user.uid
                })
                    .then(dbRes => {
                        if (dbRes.data.status === 'ok') {
                            localStorage.setItem('token', dbRes.data.token);
                            window.location.href = '/app';
                        } else {
                            window.location.reload();
                        }
                    })
            })
            .catch((err) => {
                setLoading(false);
                if (err.message.includes('wrong-password') || err.message.includes('user-not-found')) {
                    throwAlert('Invalid email or password');
                } else {
                    throwAlert('Something went wrong');
                    console.log(err.message);
                }
            })
    }

    const googleLoginHandler = async () => {
        setLoading(true);
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            await googleLoginAgainstDatabase(result.user);
        } catch (error) {
            throwAlert('Error signing in with Google. Try again.');
            setLoading(false);
        }
    };

    async function googleLoginAgainstDatabase(userData) {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/new-user`, {
                uid: userData.uid,
                name: userData.displayName,
                email: userData.email
            })

            localStorage.setItem('token', res.data.token);
            window.location.href = '/app';
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (email !== '' && email.length >= 5 && email.includes('@') && email.includes('.')) {
            setError([false, error[1]]);
        }
    }, [email]);

    useEffect(() => {
        if (password !== '' && password.length >= 8) {
            setError([error[0], false]);
        }
    }, [password]);

    return (
        <>
            <section className={`${styles.signup} container`}>
                <h1>Login</h1>
                <form onSubmit={submitHandler}>
                    <input type="email" name="email" placeholder="E-mail Address" onChange={(e) => setEmail(e.target.value)} className={`form-input ${error[0] ? 'error-input' : ''}`} autoComplete="off" value={email} />
                    {error[0] && <p className="error-feedback"><i className="fal fa-exclamation-triangle"></i> Invalid Email Address</p>}
                    <input type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} className={`form-input ${error[1] ? 'error-input' : ''}`} value={password} />
                    {error[1] && <p className="error-feedback"><i className="fal fa-exclamation-triangle"></i> Invalid Password</p>}
                    <button type="submit" className={'button ' + styles.button} disabled={loading}>{loading ? <i className="fad fa-spinner-third"></i> : 'Login'}</button>
                </form>
                <p className={styles.google}>Or, login using <span onClick={googleLoginHandler}>Google</span></p>
                <div className={styles.divider}></div>
                <p className={styles.login}>Don&apos;t have an account yet? <Link to="/signup">Sign up.</Link></p>
            </section>
            {alert[0] && <Alert message={alert[1]} />}
            {pageLoading && <FullLoading />}
        </>
    )
}

export default Login;