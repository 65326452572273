import { useState, useEffect } from 'react';
import axios from 'axios';

import UserCard from './UserCard';

import styles from './AllUsers.module.css';

const AllUsers = (props) => {
    const [users, setUsers] = useState([]);

    useEffect(async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/all-users`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (data.status === 'ok') {
            setUsers(data.users);
        } else {
            console.log(data.message);
            props.closeModalHandler();
        }
    }, [])

    return (
        <div className={styles.overlay} onClick={props.closeModalHandler}>
            <div className={styles.main} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h1>All Users</h1>
                    <button className={styles.close} onClick={props.closeModalHandler}>Close</button>
                </div>
                <div className={styles.users}>
                    {users.map(user => {
                        return <UserCard key={user.uid} user={user} viewUserHandler={props.viewUserHandler} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default AllUsers;