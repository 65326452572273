import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import RecentUsers from '../Admin/RecentUsers';
import StatsCard from '../Admin/StatsCard';
import ViewUser from '../Admin/ViewUser';
import AllUsers from '../Admin/AllUsers';
import FullLoading from '../Modals/FullLoading';

import styles from "./Admin.module.css";

const Admin = () => {
    document.title = "Dwoth Admin Panel";

    const [recentUsers, setRecentUsers] = useState([]);
    const [stats, setStats] = useState({});
    const [viewUser, setViewUser] = useState([false, null]);
    const [allUsers, setAllUsers] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/is-admin`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (data.status === 'ok') {
            setLoading(false);
        } else {
            window.location.href = '/app';
        }
    }, []);

    useEffect(async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/recent-users`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (data.status === "ok") {
            setRecentUsers(data.users);
        } else {
            console.log(data.message);
        }
    }, []);

    useEffect(async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/stats`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (data.status === 'ok') {
            setStats(data.stats)
        } else {
            console.log(data.message);
        }
    }, [])

    const viewUserHandler = async (uid) => {
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/user-details/${uid}`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (data.status === 'ok') {
            setViewUser([data.user, true]);
            setAllUsers(false);
        } else {
            console.log(data.message);
        }
    }

    return (
        <>
            <div className={styles.main}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1>Admin Panel</h1>
                        <Link to="/app">
                            Back to Dwoth
                        </Link>
                    </div>
                    <div className={styles.main1}>
                        <RecentUsers recentUsers={recentUsers} viewUserHandler={viewUserHandler} viewAllUsers={() => setAllUsers(true)} />
                        <div className={styles.cards}>
                            <h2>Stats</h2>
                            <StatsCard stat={["Number of users", stats.numberOfUsers]} />
                            <StatsCard stat={["Number of premium users", stats.numberOfPremiumUsers]} />
                            <StatsCard stat={["Activities logged today", stats.todayActivitiesCount]} />
                            <StatsCard stat={["Total activities logged", stats.overallActivitiesCount]} />
                        </div>
                    </div>
                </div>
            </div>
            {loading && <FullLoading />}
            {viewUser[0] && <ViewUser user={viewUser[0]} closeModalHandler={() => setViewUser([null, false])} />}
            {allUsers && <AllUsers closeModalHandler={() => setAllUsers(false)} viewUserHandler={viewUserHandler} />}
        </>
    )
}

export default Admin;