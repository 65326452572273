import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import EmailIcon from '../../images/email-icon.jpg';

import styles from './VerifyEmail.module.css';

const VerifyEmail = ({ name, email, throwAlert }) => {
    const [loading, setLoading] = useState(false);

    const resendLink = async() => {
        setLoading(true);
        
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/send-confirmation-email`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (data.status === 'ok') {
                throwAlert("Confirmation email sent successfully!");
            } else {
                throwAlert("Something went wrong!");
                console.log(data);
            }
        } catch (error) {
            return throwAlert("An error occurred. Please try again.");
        }

        setLoading(false);
    }

    return (
        <div className={styles.main}>
            <h1>Verify your email</h1>
            <div className={styles.modal} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
            }}>
                <img src={EmailIcon} alt="" />
                <p className={styles.infoText}>Hey {name}! We just sent an email with a verification link to your email address. Click on that link to continue using Dwoth.</p>
                <p className={styles.infoText}>Make sure to also check your spam box.</p>
                <button className="button" onClick={resendLink} disabled={loading}>{loading ? <i className="fad fa-spinner-third"></i> : 'Resend the link'}</button>
            </div>
            <p className={styles.other}>
                <Link to="/logout">Log out</Link>
            </p>
        </div>
    );
}

export default VerifyEmail;